import * as React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { ContentfulDishConnection } from '@queryTypes/contentfulDish';
import { ContentfulNavigation } from '@queryTypes/contentfulNavigation';
import Img from 'gatsby-image';
import { Layout } from '@base';

const SingleDish = styled(Link)`
  display: block;
  border: solid 1px #333;
  padding: 2rem;
  margin: 0.5rem;
`;

const DishContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const DishWrapper = styled.div`
  width: 50%;
`;

type Props = {
  data: {
    allContentfulDish: ContentfulDishConnection;
    contentfulNavigation: ContentfulNavigation;
  };
};

export default ({ data }: Props) => {
  const dishes = data.allContentfulDish.edges;
  const { contentfulNavigation } = data;

  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <meta name="description" content="Grab a slice of some tasty toast!" />
        <title>Tasty Toast</title>
      </Helmet>

      <DishContainer>
        {dishes.map(({ node: dish }) => (
          <DishWrapper key={dish.id}>
            {/* <SingleDish to={`/${dish.slug}`} key={dish.id}> */}
            <SingleDish to={`/${dish.id}`} key={dish.id}>
              <h1>{dish.title} - {dish.id}</h1>
              <Img title={dish.image.title} fixed={dish.image.fixed} />
              <p>{dish.description}</p>
              <pre>{dish.price}</pre>
            </SingleDish>
          </DishWrapper>
        ))}
      </DishContainer>
    </Layout>
  );
};

export const allDishesQuery = graphql`
  {
    allContentfulDish {
      edges {
        node {
          id
          slug
          title
          description
          price
          image {
            title
            fixed(width: 160) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
        }
      }
    }
  }
`;
